<template>
  <div class="property">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage" color="blue" background-color="#000">
    </loading>
    <schedule-calendar v-if="show.calendar" v-bind:codigo="codigo" v-bind:descricao="titulodescritivo"
      @close="toggleModal('calendar')" />
    <interest-modal v-if="show.interest" v-bind:codigo="codigo" v-bind:descricao="titulodescritivo"
      @close="toggleModal('interest')" />
    <main-header />
    <!-- Carrosel -->
    <!-- <c-button @click="forceRerender">ccc</c-button> -->
    <transition name="fade" mode="out-in">
      <!-- <property-carousel
        v-if="currentPropertyView === 'photos'"
        class="property__views"
        :slides="propertyPhotos"
        :key="1"
        :height="windowWidth * 0.5"
        :propertyVideos="propertyVideos.length > 0 ? true : false"
      /> -->
      <!-- <property-carousel
        v-if="currentPropertyView === 'photos'"
        class="property__views"
        :slides="propertyPhotos"
        :key="1"
        :height="windowWidth * 0.5"
        :propertyVideos="propertyVideos.length > 0 ? true : false"
      /> -->
      <property-grid v-if="currentPropertyView === 'photos' && !isMobile" :key="forcandoupdate" :imagens="propertyPhotosGrid"
        :propertyVideos="propertyVideos.length > 0 ? true : false" :isMobile="isMobile" :permuta="permuta">
      </property-grid>
      <property-carousel
        v-else-if="currentPropertyView === 'photos' && isMobile"
        class="property__views"
        :slides="propertyPhotos"
        :key="1"
        :height="windowWidth * 0.5"
        :propertyVideos="propertyVideos.length > 0 ? true : false"
        :isMobile="isMobile"
        :permuta="permuta"
      />
      <property-carousel v-else-if="currentPropertyView === 'videos'" class="property__views" :slides="propertyVideos"
        :key="2" type="video" :style="isMobile ? 'height: 400px !important;' : 'height: 500px !important;'"
        :propertyVideos="propertyVideos.length > 0 ? true : false" :isMobile="isMobile" />
      <div v-else class="property__views" :key="3" :style="isMobile ? 'height: 400px !important;' : 'height: 500px !important;'">
        <!-- <google-map
          :lat="latitude"
          :lng="longitude"
        >
        </google-map>-->
        <gmap-map :center="center" :zoom="options.zoom" :style="isMobile ? 'height: 400px !important; width: 100%' : 'height: 500px !important; width: 100%'">
          <!-- <gmap-cluster :zoomOnClick="true">
            <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"></gmap-info-window>
            <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
              @click="toggleInfoWindow(m)"></gmap-marker>
          </gmap-cluster> -->
          <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"></gmap-info-window>
          <GmapCircle
            v-for="(pin, index) in markers"
            :key="index"
            :center="pin.position"
            :radius="250"
            :visible="true"
            :options="{fillColor:'red',fillOpacity:.3,borderColor:'red',strokeWeight:1,strokeColor:'red'}"
            @click="toggleInfoWindow(pin)"
          ></GmapCircle>
        </gmap-map>
      </div>
    </transition>
    <!-- Toolbar carrossel -->
    <c-section class="flex property__carousel-tabs sm:justify-start">
      <c-button icon="camera" class="w-full px-0 md:w-auto md:px-6"
        :class="currentPropertyView === 'photos' ? 'primary' : 'secondary'" @click="handlePropertyView('photos')">
        <span class="ml-2">Fotos</span>
      </c-button>
      <c-button icon="video" class="w-full px-0 md:w-auto md:px-6" @click="handlePropertyView('videos')"
        :class="currentPropertyView === 'videos' ? 'primary' : 'secondary'" v-if="propertyVideos.length > 0">
        <span class="ml-2">Vídeos</span>
      </c-button>
      <c-button icon="map-marker-alt" class="w-full px-0 md:w-auto md:px-6" @click="handlePropertyView('map')"
        :class="currentPropertyView === 'map' ? 'primary' : 'secondary'">
        <span class="ml-2">Mapa</span>
      </c-button>
      <!--
      <c-button
        icon="map-marker-alt"
        class="w-full px-0 md:w-auto md:px-6"
        @click="addSkill"
      >
        <span class="ml-2">Teste de nome em cache</span>
      </c-button> -->
      <!-- <facebook
        :url="'https://www.cafeimobiliaria.com.br/imovel/3032/index.html'"
      ></facebook> -->
      <!-- <c-button class="hidden w-full md:w-auto red sm:inline-block" icon="share">
        <ShareNetwork
            network="facebook"
            :url="'https://www.cafeimobiliaria.com.br/imovel/' + codigo + '/index.html'"
            title="Imóvel tal e tal"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="subtipo + ' ' + titulodescritivo"
            hashtags="Café Inteligência Imobiliária,Imobiliária,Apartamento"
            :media="primeiraimagem"
          >
            Facebook
        </ShareNetwork>
      </c-button> -->
      <!-- <c-button class="hidden w-full md:ml-auto md:w-auto red sm:inline-block" icon="heart" />
      <c-button class="hidden w-full md:w-auto red sm:inline-block" icon="share" />-->
    </c-section>
    <!-- <share :config="config"></share> -->
    <!-- Corpo -->
    <c-section class="mx-auto property__content">
      <div class="flex flex-wrap -mx-4">
        <!-- Content -->
        <div class="w-full px-4 md:w-2/3">
          <div class="flex flex-wrap property__title divider md:flex-no-wrap" style="margin-bottom: -20px !important">
            <div class="w-full">
              <span class="flex text-xs md:text-base">
                <span class="inline-block ml-auto text-xs md:hidden" style="font-size: 1.00rem !important;">
                  COD. #{{ codigo }}
                </span>
              </span>

              <!-- <h3 class="text-xl font-medium md:font-normal md:text-4xl"> -->
              <h3 class="text-xl font-medium md:font-medium md:text-2xl">
                {{ subtipo }}
                {{ titulodescritivo }}
              </h3>
              <span v-if="contrato !== 'Compra'">{{ endereco_logradouro }}, </span>
              <span v-if="endereco_bairro !== 'Nenhum'"><!-- {{ endereco_logradouro }}, -->{{ endereco_bairro }}, {{
                endereco_cidade
              }} - {{ endestado }}</span>
              <span v-else><!-- {{ endereco_logradouro }}, -->{{ endereco_cidade }} - {{ endestado }}</span>
              <br />
              <!-- <a v-if="condominio_nome" :href="'/venda?condominio_nome=' + condominio_nome" v-show="true">
                <span class="text-primary-800">{{ condominio_nome }}</span><br>
                <span class="text-primary-500">Ver Imóveis no mesmo Condomínio</span>
              </a> -->
            </div>
            <!-- <div class="flex items-center justify-center block w-full mt-2 property__user-interactions md:hidden">
              <c-button class="w-1/2 py-2 red" icon="heart">
                <span> Favoritar</span>
              </c-button>
              <c-button class="w-1/2 py-2" icon="share">
                <span> Compartilhar</span>
              </c-button>
            </div>-->
            <div class="hidden property__code-wrapper md:block">
              <div class="property__code">
                <small>CÓDIGO</small>
                <strong>#{{ codigo }}</strong>
              </div>
              <img :src="primeiraimagem" alt="Codigo background" aria-hidden="true" />
            </div>
          </div>
          <!-- Valores no mobile -->
          <property-values @toggleModal="toggleModal($event)" class="w-full h-auto md:hidden" v-bind:contrato="contrato"
            v-bind:valor_venda="valor_venda" v-bind:valor_locacao="valor_locacao" v-bind:valor_iptu="valor_iptu"
            v-bind:valor_condominio="valor_condominio" v-bind:financiavel="financiavel" v-bind:valortotal="valortotal"
            v-bind:exclusividade="exclusividade" v-bind:status="situacao_condominio" v-bind:mobiliado="mobiliado"
            v-bind:tipo="tipo" v-bind:corretor="corretordesteimovel" />
          <!-- <div class="items-center w-full card-corretor-mobile" v-if="corretordesteimovel.length != 0" style="display: inline-block;">
          <div class="w-full lg:w-1/6" style="float: right;">
            <img :src="corretordesteimovel.avatar" alt="" style="width: 160px; height: 160px; object-fit: cover; border-radius: 50%;display: inline-flex;">
          </div>
          <ul class="w-full lg:w-5/6 mb:text-center md:text-center lg:text-right" style="">
            <li><h4 style="font-weight: bolder">Corretor {{ corretordesteimovel.nome }}</h4></li>
            <li>{{ corretordesteimovel.cargo }}</li>
            <li>{{ corretordesteimovel.email }}</li>
            <li>{{ acceptNumber(corretordesteimovel.telefone) }}</li>
            <li>
              <c-button
                class="w-full mt-1 mb-1 primary lg:w-1/6"
                style="border: none !important;border-radius: 10px !important"
                @click="toggleModal('interest')"
              >Entrar em contato</c-button>
            </li>
          </ul>
          </div> -->
          <!-- Caracteristicas -->
          <property-features class="divider" :tipo="subtipo" :tipoglobal="tipoglobal" :featureValues="[
            dormitoriossuites,
            banheiros,
            garagens_info,
            posicao_solar,
            tipo_piso,
            situacao,
            temelevador,
            terreno_medidas,
            area_mostrar,
            em_construcao
          ]" />
          <div class="text-sm property__description divider md:text-base">
            <h3 class="section-title">Detalhes do imóvel</h3>
            <p align="justify" class="pre-formatted">
              <read-more more-str="Mostrar mais" :text="observacoes" link="#" less-str="Mostrar menos"
                :max-chars="300"></read-more>
            </p>
          </div>
          <div class="property__feature-list divider" v-if="featureList.length > 0">
            <h3 class="section-title">Comodidades do imóvel</h3>
            <property-feature-list :featureList="featureList" />
          </div>
          <div class="property__condo-feature-list divider" v-if="featureList2.length > 0">
            <h3 class="section-title">Comodidades do condomínio</h3>
            <property-feature-list :featureList="featureList2" color="primary-500" />
          </div>
          <!-- Imóveis no mesmo lugar -->
          <!-- <c-section class="property__condo-feature-list"> -->
          <section class="property__condo-feature-list divider" v-if="condominio_nome && imoveis_condominio.length > 0">
            <h3 class="section-title">Imóveis no mesmo condomínio</h3>
            <c-carousel :perPageCustom="[1, 2, 2, 2, 3]">
              <template v-for="item in imoveis_condominio" :slot="`slot-${item.codigo}`">
                <property-card :key="item.codigo" v-bind:pagina="'imovel'" v-bind:codigo="item.codigo"
                  v-bind:contrato="item.contrato" v-bind:tipo="item.tipo" v-bind:subtipo="item.subtipo"
                  v-bind:imagem="item.thumb" v-bind:bairro="item.endereco_bairro" v-bind:cidade="item.endereco_cidade"
                  v-bind:estado="item.endereco_estado" v-bind:endereco="item.endereco_logradouro"
                  v-bind:empreendimento="item.condominio_nome" v-bind:dormitorios="item.dormitorios"
                  v-bind:vagas="item.garagens" v-bind:area="item.area_privativa" v-bind:valor_venda="item.valor_venda"
                  v-bind:valor_locacao="item.valor_locacao" v-bind:tags="item.tags"
                  v-bind:exclusivo="item.exclusividade" v-bind:status="item.situacao_condominio"
                  v-bind:mobiliado="item.mobiliado" />
                <!-- {{ item.imagens[0].link_thumb }} -->
              </template>
            </c-carousel>
          </section>
          <!-- Recomendações -->
          <!-- <c-section class="property__condo-feature-list"> -->
          <section class="property__condo-feature-list divider" v-if="imoveis_similares.length > 0">
            <h3 class="section-title">Imóveis similares</h3>
            <c-carousel :perPageCustom="[1, 2, 2, 2, 3]">
              <template v-for="item in imoveis_similares" :slot="`slot-${item.codigo}`">
                <property-card :key="item.codigo" v-bind:pagina="'imovel'" v-bind:codigo="item.codigo"
                  v-bind:contrato="item.contrato" v-bind:tipo="item.tipo" v-bind:subtipo="item.subtipo"
                  v-bind:imagem="item.thumb" v-bind:bairro="item.endereco_bairro" v-bind:cidade="item.endereco_cidade"
                  v-bind:estado="item.endereco_estado" v-bind:endereco="item.endereco_logradouro"
                  v-bind:empreendimento="item.condominio_nome" v-bind:dormitorios="item.dormitorios"
                  v-bind:vagas="item.garagens" v-bind:area="item.area_privativa" v-bind:valor_venda="item.valor_venda"
                  v-bind:valor_locacao="item.valor_locacao" v-bind:tags="item.tags"
                  v-bind:exclusivo="item.exclusividade" v-bind:status="item.situacao_condominio"
                  v-bind:mobiliado="item.mobiliado" />
              </template>
            </c-carousel>
          </section>
        </div>
        <!-- Sidebar -->
        <div class="self-start hidden w-full px-4 property__sidebar md:w-1/3 md:block">
          <property-values @toggleModal="toggleModal($event)" v-bind:contrato="contrato"
            v-bind:valor_venda="valor_venda" v-bind:valor_locacao="valor_locacao" v-bind:valor_iptu="valor_iptu"
            v-bind:valor_condominio="valor_condominio" v-bind:financiavel="financiavel" v-bind:valortotal="valortotal"
            v-bind:exclusividade="exclusividade" v-bind:status="situacao_condominio" v-bind:mobiliado="mobiliado"
            v-bind:tipo="tipo" v-bind:corretor="corretordesteimovel" v-bind:codigo="codigo" />
        </div>
      </div>
    </c-section>
    <!-- Duvidas -->
    <c-section class="mt-10 mb-10 md:block" :container="false">
      <div class="container pt-5 pb-5 pl-2 pr-2 m-auto text-center property__cta">
        <h3 class="mt-1 font-medium">Tem dúvidas sobre este imóvel?</h3>
        <c-button class="w-full primary lg:w-1/4" @click="toggleModal('interest')">Falar com um corretor</c-button>
        <div class="clearfix"></div>
      </div>
    </c-section>
  </div>
</template>

<script>
import PropertyValues from "../components/PropertyValues.vue"
import InterestModal from "../components/InterestModal.vue"
import CCarousel from "../components/UI/CCarousel.vue"
// import GoogleMap from "../components/GoogleMap.vue"
import ScheduleCalendar from "../components/ScheduleCalendar.vue"
import PropertyCard from "../components/PropertyCard.vue"
import PropertyFeatureList from "../components/PropertyFeatureList.vue"
import PropertyFeatures from "../components/PropertyFeatures.vue"
import CButton from "../components/UI/CButton.vue"
import CSection from "../components/UI/CSection.vue"
import PropertyCarousel from "../components/PropertyCarousel.vue"
import PropertyGrid from "../components/PropertyGrid.vue"
import MainHeader from "../components/MainHeader.vue"
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
// Importa os ícones
import { icon } from "@fortawesome/fontawesome-svg-core"
// import PictureSharesheet from "vue-picture-sharesheet"
// import { Facebook } from "vue-socialmedia-share"
import axios from "axios"

export default {
  // Meta Infos com recurso de programação
  metaInfo() {
    return {
      title: `${this.subtipo} ${this.titulodescritivo} - Café Inteligência Imobiliária`,
      meta: [
        { name: "description", content: `imóvel, imobiliária, ${this.endereco_logradouro}, ${this.endereco_bairro}, ${this.endereco_cidade}, ${this.endestado}` },
        { property: "og:title", content: `${this.subtipo} ${this.titulodescritivo}` },
        { property: "og:site_name", content: "Café Inteligência Imobiliária" },
        { name: "og:image", content: this.primeiraimagem },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" }
      ]
    }
  },
  components: {
    MainHeader,
    PropertyCarousel,
    PropertyGrid,
    CSection,
    CButton,
    PropertyFeatures,
    PropertyFeatureList,
    PropertyCard,
    ScheduleCalendar,
    // GoogleMap,
    CCarousel,
    InterestModal,
    PropertyValues,
    Loading
    // PictureSharesheet
    // Facebook
  },
  data() {
    return {
      images: [
        "1.jpg",
        "2.jpg",
        "3.jpg"
        // more images here
      ],
      colagemImv: {},
      situacao_condominio: "",
      tipoglobal: "Área Privativa",
      tiposcampeiros: ["Terreno", "Campo"],
      // config: {
      //   url: window.location.href, // 网址，默认使用 window.location.href
      //   source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
      //   title: "", // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
      //   description: "", // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
      //   image: "", // 图片, 默认取网页中第一个img标签
      //   sites: ["qzone", "qq", "weibo", "wechat", "douban"], // 启用的站点
      //   disabled: ["google"], // 禁用的站点
      //   wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
      //   wechatQrcodeHelper: "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>"
      // },
      titulodescritivo: "Carregando Informações ...",
      windowWidth: window.innerWidth,
      valortotal: 0,
      show: {
        calendar: false,
        interest: false
      },
      estaurl: window.location.href,
      urlatual: "https://www.cafeinteligencia.com.br/imovel/" + this.codigo,
      latitude: "-29.6914",
      longitude: "-53.8008 29",
      codigo: "",
      permuta: "",
      contrato: "",
      imovelpesquisado: [],
      imoveis: [],
      subtipo: "",
      tipo: "",
      endereco_logradouro: "",
      endereco_bairro: "",
      endereco_cidade: "",
      endereco_estado: "",
      endestado: "",
      observacoes: "",
      dormitorios: "",
      suites: "",
      banheiros: "",
      garagens: "",
      garagens_info: null,
      area_util: "",
      posicao_solar: "",
      tipo_solar: "",
      tipo_piso: "",
      situacao: "",
      valor_venda_visivel: "",
      valor_locacao_visivel: "",
      valor_venda: "",
      valor_locacao: "",
      valor_temporada: "",
      valor_temporada_visivel: "",
      valor_iptu: "",
      valor_condominio_visivel: "",
      valor_condominio: "",
      financiavel: 0,
      imovel_comodidades: [],
      condominio_comodidades: [],
      currentPropertyView: "photos",
      propertyPhotos: [],
      propertyPhotosGrid: [],
      ativagaleria: 0,
      imagens: [],
      propertyVideos: [],
      condominio_nome: "",
      imoveis_condominio: [],
      detalhes_condominio: {
        entrega_mes: "",
        entrega_ano: ""
      },
      em_construcao: null,
      imoveissimilares: [],
      imoveis_similares: [],
      temelevador: null,
      dormitoriossuites: null,
      area_mostrar: null,
      terreno_medidas: null,
      tags: [],
      primeiraimagem: "/img/imovel.jpg",
      isLoading: false,
      fullPage: true,
      center: { lat: -29.6914, lng: -53.8008 },
      places: [],
      markers: [],
      currentPlace: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35
        },
        width: 380
      },
      options: {
        zoom: 16
      },
      icones: {
        bed: icon({ prefix: "fas", iconName: "bed" }),
        car: icon({ prefix: "fas", iconName: "car" }),
        expandalt: icon({ prefix: "fas", iconName: "expand-alt" })
      },
      exclusividade: "",
      pesquisafeita: "",
      mobiliado: 0,
      status: "",
      corretordesteimovel: []
    }
  },
  computed: {
    featureList() {
      return this.imovel_comodidades
    },
    featureList2() {
      return this.condominio_comodidades
    },
    isMobile() {
      return this.windowWidth < 480
    },
    forcandoupdate() {
      // this.ativagaleria = this.ativagaleria + 1
      return this.ativagaleria + 1
    }
  },
  created() {
    // console.log("isMobile: " + this.isMobile)
    // console.log("Tela: " + window.innerWidth)
    // this.forceRerender()
    this.codigo = this.$route.params.codigo
    this.urlatual = "https://www.cafeinteligencia.com.br/imovel/" + this.codigo
    // if (!this.fileExiste("/jsons/" + this.codigo + ".json")) {
    // console.log("não existe")
    //   window.location.href = "/404"
    // }
    axios.get("/jsons/cafecodigos.json")
      .then((response) => {
        this.buscaentreimoveis = response.data
        this.encontrar = this.buscaentreimoveis.filter(
          d => d === this.codigo
        )
        if (this.encontrar.length === 0) {
          window.location.href = "/404"
        }

        // this.imoveis_relacionados = response.data
        // this.imoveis_condominio = this.imoveis_relacionados.filter(
        //   d => d.condominio_nome === this.condominio_nome &&
        //     d.codigo !== this.codigo
        // ).slice(0, 24)
        // var valorevidencia
        // var pontointervalomaior
        // var pontointervalomenor
        // if (this.contrato === "Compra") {
        //   valorevidencia = this.valor_venda
        // }
        // if (this.contrato === "Locacao") {
        //   valorevidencia = this.valor_locacao
        // } else {
        //   valorevidencia = this.valor_venda
        // }
        // valorevidencia = Number(valorevidencia)
        // pontointervalomaior = valorevidencia + (valorevidencia * 0.3)
        // pontointervalomenor = valorevidencia - (valorevidencia * 0.3)
        // this.imoveissimilares = response.data
        // this.imoveis_similares = this.imoveis_relacionados.filter(
        //   d => d.contrato === this.contrato &&
        //     d.codigo !== this.codigo &&
        //     d.subtipo === this.subtipo &&
        //     (Number(d.valor_venda) <= pontointervalomaior && Number(d.valor_venda) >= pontointervalomenor)
        // )
        // this.valortotal = Number(this.valor_locacao) + Number(this.valor_condominio) + Number(this.valor_iptu)
      })
    this.scrollToTop()
    this.forceRerender()
    // Início: Resgistra acesso ao Site
    axios.post(
      "https://www.cafeimobiliaria.com.br/api/registrador.php", JSON.stringify({
        fonte: window.location.href,
        formulario: "PIN-" + this.codigo,
        utm_medium: "site-imovel",
        utm_source: "site-imovel",
        utm_campaign: "site-imovel"
      }))
    // Fim: Resgistra acesso ao Site
    axios.get("/jsons/" + this.codigo + ".json")
      .then((response) => {
        this.imoveis = response.data
        this.imovelpesquisado = this.imoveis

        this.imoveis_similares = this.imovelpesquisado.similares
        this.imoveis_condominio = this.imovelpesquisado.condominares

        // Completou a seleção do imóvel
        this.imagens = this.imovelpesquisado.imagens
        for (var k in this.imagens) {
          // console.log(this.imagens[k].link + ", ")
          this.propertyPhotos.push(this.imagens[k].link)
          this.propertyPhotosGrid.push({
            source: this.imagens[k].link,
            title: "do imovel"
          })
          if (k === "0") {
            this.primeiraimagem = this.imagens[k].link_thumb
          }
        }
        this.condominio_nome = this.imovelpesquisado.condominio_nome
        this.imagens2 = this.imovelpesquisado.imagens_cond
        if (this.condominio_nome) {
          // console.log(this.imagens2[0])
          for (var l in this.imagens2) {
            this.propertyPhotos.push(this.imagens2[l])
          }
        }
        // Galeria do Empreendimento
        this.situacao_condominio = this.imovelpesquisado.situacao_condominio
        this.status = this.imovelpesquisado.status
        this.permuta = this.imovelpesquisado.permuta
        // if (this.condominio_nome) {
        //   axios.get("/condominios.json")
        //     .then((response) => {
        //       this.condominios = response.data
        //       this.condominio = this.condominios.filter(
        //         d =>
        //           // d.imagens.length > 0 &&
        //           d.nome === this.condominio_nome
        //       ).slice(0, 1)
        //       // console.log(this.condominio)
        //       this.condominio = this.condominio[0]
        //       if (this.condominio) {
        //         if (this.condominio.imagens.length > 0) {
        //           for (var k in this.condominio.imagens) {
        //             this.propertyPhotos.push(this.condominio.imagens[k].link)
        //           }
        //         }
        //         this.detalhes_condominio.entrega_mes = this.condominio.entrega_mes
        //         this.detalhes_condominio.entrega_ano = this.condominio.entrega_ano
        //         // console.log("cond")
        //         // console.log(this.condominio)
        //         if (this.status === "Em construção") {
        //           this.em_construcao = [
        //             "Em construção",
        //             this.detalhes_condominio.entrega_mes,
        //             this.detalhes_condominio.entrega_ano
        //           ]
        //         }
        //       }
        //     })
        // } else {
        if (this.status === "Em construção" && this.situacao_condominio === "Em construção") {
          this.em_construcao = [
            "Em construção",
            this.imovelpesquisado.entrega_mes,
            this.imovelpesquisado.entrega_ano
          ]
        }
        // }
        // Carregou a galeria
        this.contrato = this.imovelpesquisado.contrato
        this.subtipo = this.imovelpesquisado.subtipo
        this.tipo = this.imovelpesquisado.tipo
        if (this.tiposcampeiros.indexOf(this.subtipo) !== -1) {
          this.tipoglobal = "Área Total"
        }
        this.mobiliado = Number(this.imovelpesquisado.mobiliado)
        this.exclusividade = this.imovelpesquisado.exclusividade
        this.endereco_logradouro = this.imovelpesquisado.endereco_logradouro
        this.endereco_bairro = this.imovelpesquisado.endereco_bairro
        this.endereco_cidade = this.imovelpesquisado.endereco_cidade
        this.endereco_estado = this.imovelpesquisado.endereco_estado
        switch (this.endereco_estado) {
          case "Rio Grande do Sul":
            this.endestado = "RS"
            break
          case "Santa Catarina":
            this.endestado = "SC"
            break
          default:
            this.endestado = this.endereco_estado
            break
        }
        this.suites = this.imovelpesquisado.suites
        if (this.imovelpesquisado.dormitorios) {
          this.dormitorios = this.imovelpesquisado.dormitorios
        } else {
          this.dormitorios = 0
        }
        if (this.dormitorios > 0) {
          this.dormitoriossuites = [this.dormitorios, this.suites]
        }
        this.banheiros = this.imovelpesquisado.banheiros
        this.garagens = this.imovelpesquisado.garagens
        var vagass = ""
        if (this.garagens > 0) {
          if (this.garagens > 1) {
            vagass = "s"
          }
          this.garagens_info = this.garagens + " vaga" + vagass
        } else if (this.subtipo !== "Terreno") {
          this.garagens_info = "Sem vaga"
        }
        this.area_util = this.imovelpesquisado.area_util
        this.area_privativa = this.imovelpesquisado.area_privativa
        // Trabalhando a parte dos terrenos
        if (this.imovelpesquisado.terreno_esquerdo > 0) {
          this.terreno_comprimento = this.imovelpesquisado.terreno_esquerdo
        } else if (this.imovelpesquisado.terreno_direita > 0) {
          this.terreno_comprimento = this.imovelpesquisado.terreno_direita
        }
        if (this.imovelpesquisado.terreno_fundos > 0) {
          this.terreno_largura = this.imovelpesquisado.terreno_fundos
        } else if (this.imovelpesquisado.terreno_frente > 0) {
          this.terreno_largura = this.imovelpesquisado.terreno_frente
        }
        this.posicao_solar = this.imovelpesquisado.posicao_solar
        this.tipo_solar = this.imovelpesquisado.tipo_solar
        this.tipo_piso = this.imovelpesquisado.tipo_piso
        this.situacao = this.imovelpesquisado.status
        var situacoesaceitas = ["Novo", "Usado"]
        if (situacoesaceitas.indexOf(this.situacao) === -1) {
          this.situacao = null
        }
        this.valor_venda_visivel = this.imovelpesquisado.valor_venda_visivel
        this.valor_locacao_visivel = this.imovelpesquisado.valor_locacao_visivel
        this.valor_venda = this.imovelpesquisado.valor_venda
        this.valor_locacao = this.imovelpesquisado.valor_locacao
        this.valor_temporada = this.imovelpesquisado.valor_temporada
        this.valor_temporada_visivel = this.imovelpesquisado.valor_temporada_visivel
        this.valor_iptu = this.imovelpesquisado.valor_iptu
        if (this.valor_iptu && this.valor_iptu > 0) {
          if (this.contrato === "Locação") {
            this.valor_iptu = (this.valor_iptu / 12)
            this.valor_iptu = this.valor_iptu.toFixed(2)
          } else {
            this.valor_iptu = this.valor_iptu.toFixed(2)
          }
        }
        this.valor_condominio_visivel = this.imovelpesquisado.valor_condominio_visivel
        this.valor_condominio = this.imovelpesquisado.valor_condominio
        this.financiavel = this.imovelpesquisado.financiavel
        this.observacoes = this.imovelpesquisado.observacoes
        if (this.imovelpesquisado.latitude) {
          this.latitude = this.imovelpesquisado.latitude
        }
        if (this.imovelpesquisado.longitude) {
          this.longitude = this.imovelpesquisado.longitude
        }
        if (this.imovelpesquisado.imovel_comodidades.length > 1) {
          this.imovel_comodidades = this.imovelpesquisado.imovel_comodidades.split(",")
        } else {
          this.imovel_comodidades = []
        }
        if (this.imovelpesquisado.condominio_comodidades.length > 1) {
          this.condominio_comodidades = this.imovelpesquisado.condominio_comodidades.split(",")
          if (this.imovelpesquisado.condominio_comodidades.indexOf("Elevador") !== -1) {
            this.temelevador = "Sim"
          }
        } else {
          this.condominio_comodidades = []
        }
        // Se Terreno, não mostra elevador
        if (
          this.subtipo === "Terreno" ||
          this.subtipo === "Casa" ||
          this.subtipo === "Sobrado" ||
          this.subtipo === "Campo" ||
          this.subtipo === "Chácara" ||
          this.subtipo === "Sítio" ||
          this.subtipo === "Fazenda"
        ) {
          this.temelevador = null
          this.situacao = null
        }
        if (this.area_privativa > 0) {
          this.area_mostrar = this.area_privativa
        } else if (this.area_util > 0) {
          this.area_mostrar = this.area_util
        } else if (this.area_total > 0) {
          this.area_mostrar = this.area_total
        } else if (this.terreno_largura && this.terreno_comprimento) {
          if (this.area_total > 0) {
            this.area_mostrar = this.area_total
          } else if (this.imovelpesquisado.terreno_total > 0) {
            this.area_mostrar = this.imovelpesquisado.terreno_total
          } else {
            this.area_mostrar = Number(this.terreno_largura * this.terreno_comprimento).toFixed(1)
          }
        }
        // Imagens e Vídeos
        // Medidas do Terreno
        if (this.terreno_largura && this.terreno_comprimento) {
          this.terreno_medidas = [this.terreno_largura, this.terreno_comprimento]
          // if (this.area_total > 0) {
          //   this.area_mostrar = this.area_total
          // } else if (this.imovelpesquisado.terreno_total > 0) {
          //   this.area_mostrar = this.imovelpesquisado.terreno_total
          // } else {
          //   this.area_mostrar = Number(this.terreno_largura * this.terreno_comprimento).toFixed(1)
          // }
        } else if (this.terreno_largura) {
          this.terreno_medidas = [this.terreno_largura]
        } else if (this.terreno_comprimento) {
          this.terreno_medidas = [this.terreno_comprimento]
        }
        // if (this.area_total > 0) {
        //   this.area_mostrar = this.area_total
        // }

        /**
          <span v-if="contrato !== 'Locação'">à Venda</span>
          <span v-else>para Locação</span>
          <span v-if="area_util > 0"> com {{ formatanumero(area_util) }} m²</span>
          <span v-if="dormitorios > 0">, {{ dormitorios }} quarto<span v-if="dormitorios > 1">s</span><span v-else></span></span>
          <span v-if="garagens > 0"> e {{ garagens }} vaga<span v-if="garagens > 1">s</span><span v-else></span></span>
        */

        var formatitulodescritivo = ""
        if (this.contrato === "Locação") {
          formatitulodescritivo += " para Locação"
        } else {
          formatitulodescritivo += " à Venda"
        }
        if (this.area_util > 0) {
          formatitulodescritivo += " com " + this.formatanumero(this.area_util) + "m²"
        }
        if (this.dormitorios > 0) {
          var contratoscomerciais = [
            "Ponto Comercial", "Sala Comercial", "Sala"
          ]
          var campocomodo = " quarto"
          if (contratoscomerciais.includes(this.subtipo)) {
            campocomodo = " sala"
          }
          formatitulodescritivo += ", " + this.dormitorios + campocomodo
          if (this.dormitorios > 1) {
            formatitulodescritivo += "s"
          }
        }
        if (this.garagens > 0) {
          formatitulodescritivo += " e " + this.garagens + " vaga"
          if (this.garagens > 1) {
            formatitulodescritivo += "s"
          }
        }
        this.titulodescritivo = formatitulodescritivo

        if (this.imovelpesquisado.videos[0]) {
          // console.log(this.imovelpesquisado[0].videos[0].link)
          // var arr = this.imovelpesquisado[0].videos[0].link.split("/")
          var urlvideo = this.imovelpesquisado.videos[0].link
          // console.log(arr)
          this.propertyVideos.push("https://www.youtube.com/embed/" + urlvideo)
        }

        this.valortotal = Number(this.imovelpesquisado.valor_locacao) + Number(this.imovelpesquisado.valor_condominio) + Number(this.imovelpesquisado.valor_iptu / 12)
        // console.log(this.imovelpesquisado[0])
        // console.log(this.propertyPhotos)
        // this.propertyPhotos.each(function(index, element) {
        //   console.log(index + " - " + element)
        // })
        /*
        this.imoveis_condominio = this.imoveis.filter(
          d => d.condominio_nome === this.condominio_nome &&
          d.codigo !== this.codigo &&
          d.imagens.length > 0
        ).slice(0, 24)
        var valorevidencia
        var pontointervalomaior
        var pontointervalomenor
        if (this.contrato === "Compra") {
          valorevidencia = this.valor_venda
        }
        if (this.contrato === "Locacao") {
          valorevidencia = this.valor_locacao
        }
        valorevidencia = Number(valorevidencia)
        pontointervalomaior = valorevidencia + (valorevidencia * 0.3)
        pontointervalomenor = valorevidencia - (valorevidencia * 0.3)
        this.imoveissimilares = response.data
        this.imoveis_similares = this.imoveis.filter(
          d => d.contrato === this.contrato &&
          d.codigo !== this.codigo &&
          d.subtipo === this.subtipo &&
          d.imagens.length > 0 &&
          (Number(d.valor_venda) <= pontointervalomaior && Number(d.valor_venda) >= pontointervalomenor)
        )
        this.valortotal = Number(this.valor_locacao) + Number(this.valor_condominio) + Number(this.valor_iptu)
        */
        this.forceRerender()
        this.isLoading = false
        // axios.get("/jsons/cafeimoveis.json")
        //   .then((response) => {
        //   })
        var previamapa = "<img src='" + this.primeiraimagem + "' width='100' style='border-radius: 50%' />"
        this.addMarker(this.latitude, this.longitude, previamapa)

        if (this.imovelpesquisado.id_corretor) {
          var iddocorretor = this.imovelpesquisado.id_corretor
          // console.log("Id Corretor: " + this.imovelpesquisado.id_corretor)
          axios.get("/jsons/corretores.json")
            .then((response) => {
              // console.log("Corretores do Jetimob: ")
              // console.log("Id encontrado: " + iddocorretor)
              // console.log("Retorno:")
              // console.log(response.data)
              var resultades = response.data
              var ids = resultades.filter(
                d => d.id === iddocorretor
              )
              // console.log(ids.length !== 0)
              // console.log(ids)
              if (ids.length !== 0) {
                // console.log(ids)
                this.corretordesteimovel = ids[0]
              }
            })
        }
      })
    // this.forceRerender()
  },
  methods: {
    forceRerender() {
      this.ativagaleria += 1
      // this.$forceUpdate()
    },
    fileExiste(imageurl) {
      var http = new XMLHttpRequest()
      http.open("HEAD", imageurl, false)
      http.send()
      return http.status !== 404
    },
    scrollToTop() {
      window.scrollTo(0, 0)
      this.$forceUpdate()
    },
    toggleModal(modal) {
      this.show[modal] = !this.show[modal]
    },
    handlePropertyView(view) {
      this.currentPropertyView = view
    },
    updateWidth({ target }) {
      this.windowWidth = target.innerWidth
    },
    formatanumero(valor) {
      const formatter = Intl.NumberFormat("pt-BR")
      return formatter.format(valor)
    },
    setPlace(place) {
      this.currentPlace = place
    },
    addMarker(lat, lng, content) {
      const marker = {
        lat: lat,
        lng: lng
      }
      this.markers.push({ position: marker, infoText: content })
      this.places.push(this.currentPlace)
      this.center = marker
      this.currentPlace = null
    },
    geolocate: function (lat, lng) {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: lat,
          lng: lng
        }
      })
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position
      this.infoOptions.content = marker.infoText
      this.infoWinOpen = true
      this.currentMidx = idx
    },
    formataValor(valor) {
      const formatter = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      })
      return `${formatter.format(valor)}`
    },
    acceptNumber(valorrecebido) {
      var x = valorrecebido.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,1})(\d{0,4})(\d{0,4})/)
      valorrecebido = "(" + x[2] + ") " + x[3] + x[4] + "-" + x[5]
      return valorrecebido
    }
  },
  mounted() {
    this.isLoading = true
    this.forceRerender()
    window.scrollTo(0, 0)
    this.windowWidth = window.innerWidth
    this.$nextTick(() => window.addEventListener("resize", this.updateWidth))
  },
  destroyed() {
    window.removeEventListener("resize", this.updateWidth)
  }
}
</script>

<style lang="postcss">
.property {
  .c-section {
    @apply py-8;
  }

  .c-carousel-widget__slide {
    @apply pt-0;
  }

  .c-section+.c-section {
    @apply pt-0;
  }

  .section-title {
    @apply text-lg font-medium mb-4;
  }

  &__code-wrapper {
    @apply relative ml-auto w-24 h-24 rounded shadow-md self-center text-white overflow-hidden flex;

    ^&__code {
      @apply absolute top-0 h-full w-full top-0 left-0 flex flex-col justify-center items-center;
      background: rgba(0, 0, 0, 0.5);
    }

    img {
      @apply object-cover h-full;
    }
  }

  &__content {
    p {
      @apply mb-4;
    }
  }

  &__price-table {
    @apply mb-2;

    li {
      @apply justify-between;

      &.divider,
      &.divider+.divider {
        @apply py-2;
      }
    }
  }

  &__views {
    height: 100%;
  }

  &__sidebar {
    @apply sticky;
    top: 90px;
  }

  &__cta {
    background: url("../assets/img/cta_bg.jpg") left center/cover;

    /* Espaço entre as sessões */
    ^& &.c-section {
      @apply py-10;
    }

    h3 {
      @apply text-4xl mb-4;
    }

    &-newsletter {
      @apply my-6 mx-auto flex;

      .c-input__label {
        @apply text-left;
      }
    }
  }

  .pre-formatted {
    white-space: pre-line;
  }

  @media only screen and (max-device-width: 640px) {
    #readmore {
      background-color: #4062d1;
      border-color: #4062d1;
      color: #fff;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      white-space: nowrap;
      border-radius: 20px;
    }

    .card-corretor-mobile {
      display: inline-block;
      background: white;
      padding: 15px;
      border-radius: 15px;
    }
  }

  @media only screen and (min-device-width: 640px) {
    #readmore {
      background-color: #4062d1;
      border-color: #4062d1;
      color: #fff;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      font-size: 0.875rem;
      font-weight: 500;
      white-space: nowrap;
      border-radius: 20px;
    }
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* .z-50 {
  background-color: white !important;
} */
</style>
